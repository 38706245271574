import React, { useState, useEffect } from "react"
import Slider from "react-slick"
import ImageMeta from '../components/ImageMeta'

import Layout from "../components/layout"
import { SharedStateProvider } from "../components/SharedState/PageSharedState"

import SEO from "../components/seo"
import "../css/pages/alwaysapril.scss";

import TimedElement from '@bit/azheng.joshua-tree.timed-element';

const AlwaysApril = () => {
    let language = "en"

    const CLIENT_NAME = "Muir Oral, Facial, & Dental Implant Surgery"
    const CALENDLY_LINK = "https://calendly.com/secureform/muir-oral-facial-dental-implant-surgery-always-april";

    // Make this an empty array if there are not multiple calendlies
    const MULTIPLE_CALENDLIES = []

    const YOUTUBE = "qKo0kF7TDNg";

    // CSS changes are not sticking via useEffect,
    // so we setClient to true before changing css
    const [isClient, setClient] = useState(false)

    useEffect(() => {
        setClient(true)
    }, [])

    useEffect(() => {
        function makeNavWhite() {
            let nav = document.getElementById("mainNav");
            if (!nav) throw new Error("No nav found.")
            nav.style.backgroundColor = "white";
        }

        makeNavWhite();
    }, [isClient])

    // Slick Slider settings
    const slickSettings = {
        accessibility: true,
        dots: true,
        arrows: true,
        infinite: true,
        autoplay: true,
        adaptiveHeight: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1
    };

    return (
        <SharedStateProvider>
            <Layout pageTitle="procedure-page" language={language}>
                <SEO
                    title="Always April"
                    description={"During Oral Cancer Awareness Month, " + CLIENT_NAME + " is providing free screenings to members of our community."}
                />
                {/* <Button 
          buttonText={language === "es" ? "ATRÁS" : "BACK"}
          goBack
          /> */}

                <div className="joshua-tree-content">
                    <div className="alwaysapril">
                        <div className="subpage april-img"><img src="https://res.cloudinary.com/nuvolum/image/upload/f_auto,q_auto/v1/Programs/always-april-logo.svg" /></div>
                        <div className="april-grid">
                            <div className="april-text">
                                <p>April is Oral Cancer Awareness Month. Muir Oral, Facial, & Dental Implant Surgery has joined Always April, a national partnership of oral surgery practices across the country, to bring oral cancer awareness into a year-round discussion. We hope to do this by building awareness around the importance of regular screenings and early detection.</p>
                                <h2>The facts</h2>
                                <ul className="color-bullet">
                                    <li>Oral cancer kills about as many Americans as melanoma and twice as many as cervical cancer.
</li><li>Oral cancer is rising in women, young people, and non-smokers.
</li><li>At least 25% of oral cancer victims have no known risk factors.
</li><li>Earlier detection (stages I and II) yields survivor rates up to 80%–90%.</li>
                                </ul>
                            </div>
                            <div className="april-info">
                                <TimedElement
                                    endDate='8 April 2021 00:00:00 PST'
                                >
                                    <h2>Schedule a FREE appointment that could save your life.</h2>
                                    <span>DATE:</span>
                                    <span>Wednesday, April 7, 2021</span>
                                    <br />
                                    <span>TIME:</span>
                                    <span>12:30 PM to 4:30 PM</span>
                                    <br />
                                    <span>LOCATION:</span>
                                    <span>Muir Oral, Facial, & Dental Implant Surgery</span>
                                    <span>122 La Casa Via, Ste 223</span>
                                    <span>Walnut Creek, CA 94598</span>

                                    <br />
                                    {MULTIPLE_CALENDLIES && MULTIPLE_CALENDLIES.length > 0 ?
                                        <div className="schedule-group">
                                            {MULTIPLE_CALENDLIES.map((calendly) =>
                                                <a key={calendly.link} className="schedule" href={calendly.link} target="_blank" rel="noreferrer">SCHEDULE APPOINTMENT -<br />{calendly.cityCaps}</a>
                                            )}
                                        </div>
                                        :
                                        (CALENDLY_LINK ?
                                            <a className="schedule" href={CALENDLY_LINK} target="_blank" rel="noreferrer">SCHEDULE APPOINTMENT</a>
                                            :
                                            (null))
                                    }
                                </TimedElement>

                                <TimedElement
                                    startDate='8 April 2021 00:00:00 PST'
                                >
                                    <h2>Thank you for your interest in Always April. Our free oral cancer screening event is over. </h2>

                                    <span>It is important to be aware of changes that happen in your mouth and continue routine dental visits and cleanings. If you notice any change in your oral health, please contact your oral health provider for an examination.</span>
                                </TimedElement>

                            </div>
                        </div>
                    </div>

                    {YOUTUBE &&
                        <>
                            <div className="april-yt-wrapper">
                                <iframe src={`https://www.youtube.com/embed/${YOUTUBE}`}
                                    frameBorder='0'
                                    allow='autoplay; encrypted-media'
                                    allowFullScreen
                                    title='video'
                                    className="april-yt"
                                />
                            </div>
                        </>
                    }

                    <TimedElement
                        endDate='8 April 2021 00:00:00 PST'
                    >
                    {CALENDLY_LINK &&
                        <span className="disclaimer">*Advanced appointments requested. Local health guidelines for COVID-19 will be followed and masks are required as we look to provide a safe and healthy environment for you, our patients, staff, and&nbsp;doctors.</span>
                    }
                    </TimedElement>

                    <div className="simple-image-slider">
                        <Slider {...slickSettings}>
                            <div className="slider-image-cell">
                                <ImageMeta
                                    cloudName="nuvolum"
                                    publicId="MOFS/DEV/alwaysapril-event-1"
                                    width="auto"
                                    responsive
                                ></ImageMeta>
                            </div>
                            <div className="slider-image-cell">
                                <ImageMeta
                                    cloudName="nuvolum"
                                    publicId="MOFS/DEV/alwaysapril-event-2"
                                    width="auto"
                                    responsive
                                ></ImageMeta>
                            </div>
                        </Slider>
                    </div>
                </div>
            </Layout>
        </SharedStateProvider>
    )
}

export default AlwaysApril;